import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import { IconButton } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Recipes() {
  return (
    <Box
      component="img"
      sx={{
        margin: "5px auto",
        background: "rgba(255, 255, 255, 0.10)",
        borderRadius: ".5%",
        // height: 233,
        // width: 350,
        maxHeight: "200px",
        maxWidth: "100%",
      }}
      alt="Looks delicous."
      src={
        "https://firebasestorage.googleapis.com/v0/b/recipebrigade.appspot.com/o/images%2FRecipeBrigadeDude-01.png?alt=media&token=12618894-3b94-4e08-a55f-c64bda7a93ae"
      }
    />
  );
}
